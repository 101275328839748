<template>
  <div class="merchants">
    <div class="merchants_bg">
      <!-- <img class="" src="../../assets/img/merchantsBg.png" alt="" /> -->
    </div>

    <MerchantsHeader></MerchantsHeader>

    <div class="merchants_info">
      <div class="head_img">
        <img
          class="info_img"
          :src="merchantsInfo.StoreHeadImage || defaultPic"
          alt=""
          srcset=""
        />
      </div>

      <div class="info">
        <div class="name">
          {{ merchantsInfo.StoreName || merchantsInfo.LoginName }}
        </div>
        <div>
          <span class="num">{{ merchantsCount.support || 0 }}</span>
          <span> 留言</span>
          <span class="num">{{ merchantsCount.fans || 0 }}</span>
          <span> 粉丝</span>
          <span class="num">{{ merchantsCount.follow || 0 }}</span>
          <span> 关注</span>
        </div>
        <div class="address">
          <img
            v-if="merchantsInfo.Id == 44"
            class="official_icon"
            src="../../assets/icon/official.png"
            alt=""
          />
          <img
            v-else-if="merchantsInfo.CertificationType"
            class="official_icon2"
            src="../../assets/icon/certification.png"
            alt=""
          />
          <img
            v-else
            class="official_icon2"
            src="../../assets/icon/no_certification.png"
            alt=""
          />
          <i class="el-icon-location-information position_icon"></i>
          <span>{{ merchantsInfo.Province || '' }}</span>
          <span>{{ merchantsInfo.City || '' }}</span>
          <span>{{ merchantsInfo.Area || '' }}</span>
        </div>
        <div class="describe">
          {{ merchantsInfo.StoreBriefIntroduction }}
        </div>
      </div>

      <div class="focus" v-if="userInfo.Id !== merchantsInfo.Id">
        <div
          :class="focusFlag ? 'focus_on' : 'focus_btn'"
          @click="$globalFun.throttle(addFocus, 1000, true)"
        >
          {{ focusFlag ? '已关注' : '关注' }}
        </div>
      </div>
    </div>

    <div class="release">
      <div class="release_left">
        <MerchantsRelease
          :isAm="userInfo.Id == merchantsInfo.Id"
          :dataList="merchantsArticleList"
        ></MerchantsRelease>
      </div>
      <div class="release_right">
        <div class="release_right_title">近期热门</div>
        <MerchantsReleaseHot
          :dataList="merchantsArticleList.slice(0, 10)"
          @goDetails="goDetails"
        ></MerchantsReleaseHot>
      </div>
    </div>

    <AllFooter></AllFooter>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userInfo: JSON.parse(localStorage.getItem('info') || null),
      defaultPic: 'https://api.chajiuqu.com/uploadImage/img/defaultPict.png',

      merchantsInfo: {},
      merchantsCount: {},
      merchantsArticleList: [],

      focusFlag: false
    }
  },

  created() {
    const isLogin = this.$store.state.tokens
    if (!isLogin) {
      this.$router.push({
        path: '/login',
      })
    }

    document.documentElement.scrollTop = 0

    this.getInfo(this.$route.query.id)
    this.getStoreCount(this.$route.query.id)
    this.getCollect(this.$route.query.id)
  },

  methods: {
    async getInfo(Id) {
      const { data } = await this.$api.user.getInfo({ Id })
      console.log('商户信息', data)
      this.merchantsInfo = data

      // if () this.merchantsInfo.StoreHeadImage

      this.getArticleList(Id)
    },

    async getArticleList(userId) {
      const { data } = await this.$api.user.articleListUser({
        pageindex: 1,
        userId,
      })
      console.log('用户发布文章', data)
      this.merchantsArticleList = data.list
    },

    goDetails(id) {
      if (this.$route.query.id == id) return
      this.$router.push({
        path: '/merchants/details',
        query: {
          id,
        },
      })
    },

    // 获取粉丝关注数目
    async getStoreCount(userId) {
      const { data } = await this.$api.user.getStoreCount({
        userId,
      })
      console.log('获取粉丝数', data)
      this.merchantsCount = data
    },

    // 获取关注商家
    async getCollect(userId) {
      const { data: res } = await this.$api.user.getCollect({
        userId,
      })
      console.log('获取关注商家', res)
      if (res) this.focusFlag = true
    },

    // 添加/删除关注
    addFocus() {
      if (!this.focusFlag) {
        this.addCollect()
      } else {
        this.delCollect()
      }
    },

    // 添加关注
    async addCollect() {
      const { data: res } = await this.$api.user.addCollect({
        userId: this.$route.query.id,
      })
      console.log('添加关注', res)
      this.$message({
        message: '关注成功',
        type: 'success',
      })
      this.focusFlag = true
      console.log(this.focusFlag)
    },
    // 删除关注
    async delCollect() {
      const { data: res } = await this.$api.user.delCollect({
        userId: this.$route.query.id,
      })
      console.log('删除关注', res)
      this.$message({
        message: '删除关注成功',
        type: 'warning',
      })
      this.focusFlag = false
      console.log(this.focusFlag)
    },
  },
}
</script>

<style lang="scss" scoped>
.merchants {
  width: 100%;
  position: relative;

  .merchants_bg {
    width: 100%;
    height: 410px;
    background: url('../../assets/img/merchantsBg.png') no-repeat;
    background-size: contain;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  .merchants_info {
    width: 1126px;
    margin: 55px auto 0;
    display: flex;
    align-items: center;

    .head_img {
      .info_img {
        width: 114px;
        height: 114px;
        display: block;
        border-radius: 50%;
      }
    }

    .info {
      flex: 1;
      margin-left: 24px;
      text-align: left;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #797979;

      .name {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: 600;
        color: #3d3d3d;
        margin-bottom: 10px;
      }

      .num {
        margin-left: 40px;
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: 600;
        color: #3d3d3d;
      }

      .num:first-child {
        margin-left: 0;
      }

      .address {
        margin-top: 10px;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;

        .official_icon {
          width: 91px;
          height: 20px;
          display: block;
          margin-right: 4px;
        }

        .official_icon2 {
          width: 83px;
          height: 22px;
          display: block;
          margin-right: 4px;
        }

        .position_icon {
          font-size: 14px;
          color: #b9b9b9;
          margin-right: 4px;
        }
      }

      .describe {
        margin-top: 10px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
      }
    }

    .focus {
      width: 320px;
      display: flex;
      align-items: center;
      justify-content: center;

      .focus_btn {
        width: 175px;
        height: 41px;
        line-height: 41px;
        text-align: center;
        background: rgba(216, 62, 38, 1);
        border-radius: 5px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        cursor: pointer;
      }

      .focus_on {
        width: 173px;
        height: 39px;
        margin: 12px auto 0;
        line-height: 39px;
        text-align: center;
        border-radius: 5px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ababab;
        cursor: pointer;
        border: 1px solid #d6d6d6;
      }
    }
  }

  .release {
    width: 1126px;
    min-height: 400px;
    margin: 30px auto 0;
    display: flex;

    .release_left {
      flex: 1;
    }

    .release_right {
      margin-left: 56px;
      width: 320px;
      text-align: left;

      .release_right_title {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 600;
        line-height: 25px;
        color: #222222;
      }
    }
  }
}
</style>
